import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Seo } from '../components/utils'
import { TextContainer } from '../components/layout/TextContainer'

export default function PrivacyPolicyPage() {
  const { policyData } = useStaticQuery(graphql`
    {
      policyData: sanityPrivacyPolicy {
        title
        _rawBody
      }
    }
  `)
  return (
    <>
      <Seo title="Datenschutzerklärung" />
      <TextContainer heading={policyData.title} body={policyData._rawBody} />
    </>
  )
}
